import React from "react";
import {
  DefaultCaseImage,
  DefaultCaseInfo,
  DefaultCasePreTitle,
  DefaultCaseResetButton,
  DefaultCaseResetIcon,
  DefaultCaseSubTitle,
  DefaultCaseTitle,
  DefaultCaseWrapper,
} from "./styles";
import DefaultCaseImg from "../../assets/icons/DefaultCaseImg";
import ResetArrowIcon from "../../assets/icons/ResetArrowIcon";

const NdaTemplate = ({ title, text, reset }) => {
  return (
    <DefaultCaseWrapper>
      <DefaultCaseInfo>
        <DefaultCasePreTitle variant="h4">Sorry</DefaultCasePreTitle>
        <DefaultCaseTitle variant="h1">
          <strong>{title}</strong>
        </DefaultCaseTitle>
        <DefaultCaseSubTitle variant="subtitle1">
          {text}
        </DefaultCaseSubTitle>
        <DefaultCaseResetButton onClick={reset}>
          Reset filter
          <DefaultCaseResetIcon>
            <ResetArrowIcon />
          </DefaultCaseResetIcon>
        </DefaultCaseResetButton>
      </DefaultCaseInfo>
      <DefaultCaseImage>
        <DefaultCaseImg />
      </DefaultCaseImage>
    </DefaultCaseWrapper>
  );
};

export default NdaTemplate;

import React from "react";
import clsx from "clsx";
import {
  CasePreviewDescription,
  CasePreviewMainCategory,
  CasePreviewTitle,
  LinkArrow,
  LinkContainer,
  StyledCardContainer,
} from "../style";
import SliderSmallArrow from "../../../assets/icons/SliderSmallArrow";
import { Link } from "gatsby";
import { useBreakpointValue } from "../../../hooks/use-breakpoint-value";

const CardContainer = ({
  visibleCard,
  mainCategory,
  title,
  description,
  url,
}) => {
  const isMobile = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);

  const LinkMobile = isMobile ? Link : "div";

  return (
    <StyledCardContainer
      className={clsx({
        visibleCard: visibleCard,
      })}
    >
      <CasePreviewMainCategory>{mainCategory}</CasePreviewMainCategory>
      <CasePreviewTitle variant="h4" component="h2">
        {title}
      </CasePreviewTitle>
      <CasePreviewDescription
        variant="subtitle1"
        component="div"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      <LinkContainer>
        {/* The link will need to be replaced with a link to a specific page */}
        <LinkMobile {...(isMobile && { to: url })} className="linkMobile">
          Watch case
          <LinkArrow>
            <SliderSmallArrow />
          </LinkArrow>
        </LinkMobile>
      </LinkContainer>
    </StyledCardContainer>
  );
};

export default CardContainer;

import React from "react";
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";
import Box from "@mui/material/Box";

export const Arrow = (props) => {
  const { className } = props;

  const isUp = className.includes("MuiSelect-iconOpen");

  return (
    <Box
      className="arrowWrapper"
      {...(isUp && {
        sx: {
          transform: "rotate(180deg)",
        },
      })}
    >
      <ArrowDownIcon />
    </Box>
  );
};

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useMemo } from "react";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import {
  CaseSelectWrapper,
  CasesFilter,
  CasesFilterContainer, CasesLoader,
  CasesPortfolio,
  FilterForm,
  ResetWrapper,
  StyledLabel,
} from "../../styles/cases";
import theme from "../../theme";
import AutoSave from "../autoSave";
import CasesPreview from "../casesPreview";
import NdaTemplate from "../ndaTemplate";
import CustomSelect from "../select";
import ControlledSelect from "../select/ControlledSelect";
import queryString from "query-string";
import { navigate } from "gatsby";
import Loader from "../loader";

const getItemBySlug = ({ slug, data = [] }) => {
  return data.find((item) => item.attributes.slug === slug);
};

const getItemsBySlug = ({ slugs = [], data = [] }) => {
  return data.filter((item) => slugs.includes(item.attributes.slug));
};

const getSlugByCategoryId = ({ id = null, data = [] }) => {
  return data.find((item) => item.id === id)?.attributes.slug || "";
};

const getSlugsByTechnologyId = ({ technologies = [], data = [] }) => {
  const ids = technologies.map((el) => el.id);

  return data.reduce((acc, next) => {
    if (ids.includes(next.id)) {
      acc.push(next.attributes.slug);
    }

    return acc;
  }, []);
};

const CasesContent = ({
                        categoryIsEmpty,
                        values,
                        resetForm,
                        formikHandleSubmit,
                        onChange,
                        categories,
                        cases,
                        filteredCases,
                        search,
                        setFieldValue,
                        technologiesLists,
                        pathname,
                        loading,
                      }) => {
  const renderSelectValue = (value) => {
    return (
        categories?.data.find((el) => el.id === value)?.attributes?.preview
            .title || "All"
    );
  };

  const handleResetFilter = (resetForm) => () => {
    navigate(`${pathname}`);
    resetForm();
  };

  const casesToDisplay = useMemo(() => {
    return filteredCases?.cases || cases;
  }, [cases, filteredCases]);

  const stackTechnologyOptions = useMemo(() => {
    if (values.category !== "all") {
      const neededCases =
          filteredCases?.cases.data.flatMap((item) => {
            return item?.attributes?.stackTechnologies?.data;
          }) || [];

      return [
        ...new Map(neededCases.map((item) => [item["id"], item])).values(),
      ];
    }
    const allStackTechnologiesByCases =
        cases?.data?.flatMap((item) => {
          return item?.attributes?.stackTechnologies?.data;
        }) || [];

    return [
      ...new Map(
          allStackTechnologiesByCases.map((item) => [item["id"], item])
      ).values(),
    ];
  }, [cases?.data, values.category, filteredCases?.cases]);

  const handleChangeCategoryUrl = (event) => {
    const {
      target: { value: categoryId },
    } = event;

    const slug = getSlugByCategoryId({
      id: categoryId,
      data: categories?.data,
    });

    if (slug) {
      const queryObject = {
        ...queryString.parse(search),
        category: slug,
      };

      const queryStr = queryString.stringify(queryObject, {
        arrayFormat: "comma",
      });

      navigate(`${pathname}?${queryStr}`);
    } else {
      const queryObject = {
        ...queryString.parse(search, {
          arrayFormat: "comma",
        }),
      };

      delete queryObject.category;

      let queryStr = queryString.stringify(queryObject, {
        arrayFormat: "comma",
      });

      if (queryStr.length) queryStr = `?${queryStr}`;

      navigate(`${pathname}${queryStr}`);
    }
  };

  const handleChangeTechnologyUrl = (technologies) => {
    const slugs = getSlugsByTechnologyId({
      technologies: technologies,
      data: technologiesLists?.data,
    });

    if (slugs.length) {
      const queryObject = {
        ...queryString.parse(search),
        stackTechnology: slugs,
      };

      const queryStr = queryString.stringify(queryObject, {
        arrayFormat: "comma",
      });

      navigate(`${pathname}?${queryStr}`);
    } else {
      const queryObject = {
        ...queryString.parse(search),
      };

      delete queryObject.stackTechnology;

      let queryStr = queryString.stringify(queryObject, {
        arrayFormat: "comma",
      });

      if (queryStr.length) queryStr = `?${queryStr}`;

      navigate(`${pathname}${queryStr}`);
    }
  };

  useEffect(() => {
    const searchObj = queryString.parse(search, { arrayFormat: "comma" });

    if (Object.keys(searchObj).length) {
      const category = getItemBySlug({
        slug: searchObj.category,
        data: categories?.data,
      });
      const stackTechnologies = getItemsBySlug({
        slugs:
            typeof searchObj.stackTechnology === "string"
                ? [searchObj.stackTechnology]
                : searchObj.stackTechnology,
        data: technologiesLists?.data,
      });

      if (category) setFieldValue("category", category?.id);
      if (stackTechnologies.length)
        setFieldValue("stackTechnology", stackTechnologies);
    }
  }, [categories?.data, search, setFieldValue, technologiesLists?.data]);

  return (
      <>
        <FilterForm onSubmit={formikHandleSubmit}>
          <AutoSave />
          <CasesFilter>
            <CasesFilterContainer>
              <CaseSelectWrapper>
                <CustomSelect
                    name="category"
                    value={values.category}
                    label="Category"
                    width="280px"
                    renderValue={renderSelectValue}
                    handleOnChange={handleChangeCategoryUrl}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  {categories?.data.map((item) => {
                    return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.attributes.preview.title}
                        </MenuItem>
                    );
                  })}
                </CustomSelect>
                <Box
                    maxWidth={theme.breakpoints.down("md") ? "unset" : "455px"}
                    width="100%"
                >
                  <StyledLabel>Stack technology</StyledLabel>
                  <ControlledSelect
                      options={stackTechnologyOptions}
                      value={values.stackTechnology}
                      onChange={onChange}
                      handleChange={handleChangeTechnologyUrl}
                      name="stackTechnology"
                  />
                </Box>
              </CaseSelectWrapper>
              <ResetWrapper onClick={handleResetFilter(resetForm)}>
                Reset filter
                <DeleteIcon />
              </ResetWrapper>
            </CasesFilterContainer>
          </CasesFilter>
        </FilterForm>
        {/* CASES PORTFOLIO */}
        <CasesPortfolio>
          {loading ?
              <CasesLoader>
                <Loader />
              </CasesLoader>
              : (
                  <>
                    {casesToDisplay?.data.length === 0 ? (
                        <NdaTemplate
                            title={
                              categories?.data.find((el) => el.id === values.category)
                                  ?.attributes.preview.title
                            }
                            text={categoryIsEmpty?.data?.attributes?.text}
                            reset={handleResetFilter(resetForm)}
                        />
                    ) : (
                        casesToDisplay?.data.map((item) => {
                          return <CasesPreview key={item.id} data={item} />;
                        })
                    )}
                  </>
              )
          }
        </CasesPortfolio>
        {/* END OF CASES PORTFOLIO */}
      </>
  );
};

export default CasesContent;
import { useFormikContext } from "formik";
import React, { useEffect } from "react";

const AutoSave = () => {
  const formik = useFormikContext();

  useEffect(() => {
    formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.submitForm, formik.values]);

  return <></>;
};

export default AutoSave;

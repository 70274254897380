import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83329 3.33317V1.6665L14.1666 1.6665V3.33317L18.3333 3.33317V4.99984L16.6666 4.99984V17.4998C16.6666 17.7209 16.5788 17.9328 16.4225 18.0891C16.2663 18.2454 16.0543 18.3332 15.8333 18.3332L4.16663 18.3332C3.94561 18.3332 3.73365 18.2454 3.57737 18.0891C3.42109 17.9328 3.33329 17.7209 3.33329 17.4998L3.33329 4.99984H1.66663L1.66663 3.33317L5.83329 3.33317ZM4.99996 4.99984L4.99996 16.6665H15L15 4.99984L4.99996 4.99984ZM7.49996 7.49984H9.16663L9.16663 14.1665H7.49996L7.49996 7.49984ZM10.8333 7.49984H12.5V14.1665H10.8333V7.49984Z"
        fill="#5A32FB"
      />
    </svg>
  );
};

export default DeleteIcon;

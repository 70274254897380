import { styled } from "@mui/system";
import palette from "../../theme/palette";
import FormControl from "@mui/material/FormControl";
import theme from "../../theme";
import Box from "@mui/material/Box";

export const SelectWrapper = styled(FormControl)({
  width: "100%",
  "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select":
    {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "0.4px",
      width: "100%",
      padding: "12px 18px 12px 0px",
      [theme.breakpoints.down("md")]: {
        color: palette.purpleBlue,
      },
      "& .Mui-selected": {
        backgroundColor: "rgb(155, 81, 224)",
        color: "#fff",
        "&:hover": {
          backgroundColor: "#9B51E0",
        },
      },
    },
  "& .arrowWrapper": {
    position: "absolute",
    right: 0,
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    minWidth: "20px",
    minHeight: "20px",
    maxHeight: "20px",
    cursor: "pointer",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    top: "unset",
    border: "none",
    borderBottom: "1px solid #EDEDF8",
    legend: {
      display: "none",
    },
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline, & :hover .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#EDEDF8 !important",
      borderWidth: "1px !important",
    },
  fieldset: {
    borderColor: "#EDEDF8",
  },
});

export const LabelWrapper = styled(Box)({
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "16px",
  letterSpacing: "0.4px",
  color: palette.amethystSmoke,
});

export const styles = {
  selectMenu: {
    boxShadow: "0px 24px 48px -12px #1515152E",
    maxHeight: "414px",
    [theme.breakpoints.down("md")]: {
      maxHeight: "586px",
    },
    "& .MuiList-root.MuiMenu-list": {
      padding: "20px 26px",
      [theme.breakpoints.down("md")]: {
        padding: "10px 16px ",
      },
    },
    "& .MuiButtonBase-root.MuiMenuItem-root": {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "0.4px",
      color: palette.primary,
      padding: "6px 0px",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.Mui-selected": {
        color: palette.purpleBlue,
        backgroundColor: "transparent",
        listStyle: "square outside none",
        display: "list-item",
        marginLeft: "17px",
      },
    },
  },
  multipleSelectMenu: {
    "& .MuiList-root.MuiMenu-list": {
      padding: "20px 26px",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gap: "0px 8px",
      [theme.breakpoints.down("md")]: {
        padding: "10px 16px",
        gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
      },
    },
  },
  selectContainer: {
    width: "100%",
    height: "47px",
    cursor: "pointer",
    borderBottom: "1px solid #EDEDF8",
    maxHeight: "47px",
    borderRadius: "3px",
    boxSizing: "border-box",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    position: "relative",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0.4px",
    padding: "12px 18px 12px 0px",
    [theme.breakpoints.down("md")]: {
      color: palette.purpleBlue,
    },
    "& .Mui-selected": {
      backgroundColor: "rgb(155, 81, 224)",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#9B51E0",
      },
    },

    "& svg": {
      marginLeft: "33px",
      minWidth: "20px",
      minHeight: "20px",
      maxHeight: "20px",
      position: "absolute",
      right: "0px",
      top: "13px",
    },
  },
  transformChevron: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  menuListContainer: {
    "& .selected": {
      "&.MuiButtonBase-root.MuiMenuItem-root": {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: "0.4px",
        color: palette.purpleBlue,
        backgroundColor: "transparent",
        listStyle: "square outside none",
        display: "list-item",
        marginLeft: "17px",
        padding: "6px 0px",
      },
    },
  },
  wrapItem: {
    border: "solid 1px black",
    margin: 1,
    borderRadius: "4px",
    padding: "2px 12px 2px 12px",
  },
  paperContainer: {
    boxShadow: "0px 24px 48px -12px #1515152E",
    maxHeight: "440px",
    "&.oneItems": {
      "& .actionBtnContainer": {
        flexWrap: "wrap",
        bottom: "10px",
        padding: "0px 26px",
        [theme.breakpoints.down("md")]: {
          flexWrap: "unset",
        },
        "& button.MuiButtonBase-root.MuiButton-root": {
          marginRight: "0px",
          width: "100%",
          "&:last-child": {
            marginTop: "5px",
            [theme.breakpoints.down("md")]: {
              marginTop: "0px",
            },
          },
        },
      },
      "&.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper .MuiList-root.MuiMenu-list":
        {
          paddingBottom: "110px",
          [theme.breakpoints.down("md")]: {
            paddingBottom: "60px",
          },
        },
    },

    "&::-webkit-scrollbar": {
      width: "6px",
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px #d6dde0",
      borderRadius: "3px",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#151515",
      borderRadius: "3px",
    },
    scrollbarColor: "#151515 #d6dde0",
    scrollbaridWth: "thin",

    [theme.breakpoints.down("md")]: {
      maxHeight: "586px",
      width: "100%",
    },
    "& .MuiList-root.MuiMenu-list": {
      padding: "20px 26px",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gap: "0px 8px",
      position: "relative",
      paddingBottom: "94px",
      [theme.breakpoints.down("md")]: {
        padding: "10px 16px 94px 16px",
        gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
      },
    },
    "& .MuiButtonBase-root.MuiMenuItem-root": {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "0.4px",
      color: palette.primary,
      padding: "6px 0px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  selectBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: "26px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      padding: "0px 16px",
    },
    "& button.MuiButtonBase-root.MuiButton-root ": {
      height: "42px",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "21px",
      padding: "10.5px 36px",
      minWidth: "unset",
      border: "1px solid #151515",
      borderRadius: "0px",
      color: palette.primary,
      marginRight: "26px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "142.5px",
        width: "100%",
        marginRight: "0px",
        backgroundColor: palette.porcelain,
        borderColor: palette.porcelain,
        "&:last-child": {
          color: "#ffffff",
          backgroundColor: palette.purpleBlue,
          borderColor: palette.purpleBlue,
          marginLeft: "10px",
        },
      },
      "&:hover": {
        backgroundColor: palette.porcelain,
        borderColor: palette.porcelain,
        "&:last-child": {
          color: "#ffffff",
          backgroundColor: palette.purpleBlue,
          borderColor: palette.purpleBlue,
        },
      },
    },
  },
};

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

import { styles } from "./styles";
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";

const AllObj = {
  id: null,
  attributes: {
    title: "All",
  },
};

const ControlledSelect = ({
  value = [],
  onChange,
  handleChange,
  options = [],
  placeholder,
  controlledSelectWrapper,
  paperContainer,
  name,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const open = Boolean(anchorEl);
  const isSelected = (option) =>
    Boolean(selected.find((item) => item.id === option.id));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelected(value);
    setAnchorEl(null);
  };

  const handleSelectItem = (option) => () => {
    setSelected((prev) => {
      const isOptionSelected = isSelected(option);

      // select new option
      if (option.attributes?.title !== "All") {
        if (isOptionSelected) {
          const filtered = prev.filter((item) => item.id !== option.id);

          return filtered.length ? filtered : [AllObj];
        }

        return [...prev.filter((el) => el.attributes?.title !== "All"), option];
      }

      return [AllObj];
    });
  };

  const handleApply = () => {
    onChange(name)({ target: { value: selected } });
    handleChange?.(selected);
    handleClose();
  };

  const internalRenderValue = () => {
    if (!selected.length && placeholder) return placeholder;
    return selected.map((item) => item.attributes?.title || item).join(", ");
  };

  return (
    <>
      <Box
        sx={{
          ...controlledSelectWrapper,
          ...styles.selectContainer,
        }}
        onClick={handleClick}
      >
        {internalRenderValue()}
        <Box sx={open ? styles.transformChevron : undefined}>
          <ArrowDownIcon />
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: styles.menuListContainer,
        }}
        PaperProps={{
          sx: {
            ...styles.paperContainer,
            ...paperContainer,
          },
          className: options.length <= 1 ? "oneItems" : undefined,
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={handleSelectItem(AllObj)}
          className={isSelected(AllObj) ? "selected" : undefined}
        >
          All
        </MenuItem>
        {options?.map((option) => {
          return (
            <MenuItem
              key={option.id}
              onClick={handleSelectItem(option)}
              className={isSelected(option) ? "selected" : undefined}
            >
              {option.attributes.title}
            </MenuItem>
          );
        })}
        <Box sx={styles.selectBtnContainer} className="actionBtnContainer">
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleApply}>Apply</Button>
        </Box>
      </Menu>
    </>
  );
};

export default ControlledSelect;

import palette from "../theme/palette";
import { styled } from "@mui/system";
import theme from "../theme";
import { Form } from "formik";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const CasesTitle = styled(Typography)({
  maxWidth: "972px",
  width: "100%",
  margin: "36px 0",
  fontWeight: "600",

  [theme.breakpoints.down("xl")]: {
    maxWidth: "100%",
  },

  [theme.breakpoints.down("lg")]: {
    margin: "44px 0 16px",
  },
});

// CASES FILTER
export const CasesFilter = styled(Box)({
  marginBottom: "52px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "0px",
  },
});

export const CasesFilterContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
});

export const CasesFilterContent = styled(Box)({
  backgroundColor: palette.porcelain,
  padding: "10.5px 22px",
  borderRadius: 8,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "21px",
  marginRight: 10,
  whiteSpace: "nowrap",
  cursor: "pointer",

  "&:last-child": {
    marginRight: 0,
  },

  "&.filterContentActive": {
    backgroundColor: palette.black,
    color: palette.secondary,
  },
});
// CASES FILTER

// CASES PORTFOLIO
export const CasesPortfolio = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  marginTop: "120px",

  [theme.breakpoints.down("xl")]: {
    marginTop: "44px",
  },
});
// CASES PORTFOLIO

// CASES LOADER
export const CasesLoader = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
});
// CASES LOADER

export const FilterForm = styled(Form)({
  marginBottom: "0px",
});

export const ResetWrapper = styled(Box)({
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  letterSpacing: "0.1px",
  padding: "12px 0px",
  borderBottom: "1px solid #EDEDF8",
  color: palette.purpleBlue,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  marginLeft: "20px",
  minWidth: "101px",
  userSelect: "none",

  svg: {
    minWidth: "20px",
    minHeight: "20px",
    marginLeft: "6px",
  },
});

export const CaseSelectWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "855px",
  width: "100%",
  "& .MuiFormControl-root:first-of-type": {
    marginRight: "20px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    "& .MuiFormControl-root": {
      marginBottom: "26px",
      maxWidth: "unset",
      "&:first-of-type": {
        marginRight: "0px",
      },
    },
  },
});

export const StyledLabel = styled(Box)({
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "16px",
  letterSpacing: "0.4px",
  color: palette.amethystSmoke,
});

import { gql } from "@apollo/client";

export const getCasesGQL = gql`
  query Cases($filters: CaseFiltersInput, $pagination: PaginationArg) {
    cases(filters: $filters, pagination: $pagination, sort: ["sort:desc","createdAt:desc"]) {
      data {
        id
        attributes {
          slug
          title
          description
          mainImage {
            data {
              attributes {
                url
                alternativeText
                caption
              }
            }
          }
          mainCategory {
            data {
              attributes {
                preview {
                    title
                }
              }
            }
          }
          stackTechnologies {
            data {
              id
              attributes {
                title
              }
            }
          }
        }
      }
    }
  }
`;

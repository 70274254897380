import palette from "../../theme/palette";
import { styled } from "@mui/system";
import theme from "../../theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const PortfolioWrapper = styled(Box)({
  position: "relative",
  maxWidth: "580px",
  width: "100%",
  height: "400px",
  marginBottom: "26px",
  background: "linear-gradient(0deg, rgba(21, 21, 21), rgba(21, 21, 21))",

  "& .wrapper": {
    display: "flex",
    width: "100%",
    height: "100%",
  },

  "&:last-child": {
    marginBottom: 0,
  },

  "&:nth-of-type(2n)": {
    marginTop: "-68px",
  },

  "&:nth-of-type(2n+1)": {
    marginRight: "18px",
  },

  "& img": {
    width: "100%",
    height: "100%",
    margin: 0,
    objectFit: "cover",
    objectPosition: "center",
    opacity: 1,
  },

  "&:hover": {
    "& img": {
      opacity: 0.7,
    },
  },

  [theme.breakpoints.down("xl")]: {
    maxWidth: "100%",
    height: "auto",
    background: "none",

    "&:nth-of-type(2n)": {
      marginTop: 0,
    },

    "&:nth-of-type(2n+1)": {
      marginRight: 0,
    },

    "& .imageWrapper": {
      maxWidth: "50%",
      height: "400px",
    },

    "& img": {
      display: "block",
    },

    "&:hover": {
      "& img": {
        opacity: 1,
      },
    },
  },

  [theme.breakpoints.down("lg")]: {
    marginBottom: "16px",

    "& .wrapper": {
      flexDirection: "column",
    },

    "& img": {
      maxWidth: "unset",
    },
  },

  [theme.breakpoints.down("sm")]: {
    "& img": {
      width: "calc(100% + 32px)",
      height: "260px",
      marginLeft: "-16px",
    },
  },
});

export const StyledCardContainer = styled(Box)({
  position: "absolute",
  bottom: "46px",
  left: "46px",
  right: "46px",
  maxWidth: "472px",
  width: "100%",
  padding: "36px",
  backgroundColor: palette.secondary,
  transition: "opacity 0.4s ease-out",
  opacity: 0,

  "&.visibleCard": {
    opacity: 1,
  },

  [theme.breakpoints.down("xl")]: {
    position: "unset",
    padding: "16px 0 16px 26px",
    opacity: 1,
  },

  [theme.breakpoints.down("lg")]: {
    paddingLeft: 0,
  },
});

export const CasePreviewMainCategory = styled(Box)({
  color: palette.purpleBlue,
  fontSize: "10px",
  fontWeight: 700,
  lineHeight: "14px",
  textTransform: "uppercase",
  letterSpacing: "1.5px",
});

export const CasePreviewTitle = styled(Typography)({
  letterSpacing: "-0.01em",
});

export const CasePreviewDescription = styled(Typography)({
  margin: "8px 0 26px",
  letterSpacing: "0.1px",

  [theme.breakpoints.down("lg")]: {
    margin: "8px 0",
  },
});

export const LinkContainer = styled(Box)({
  display: "flex",

  "& .linkMobile": {
    display: "flex",
    alignItems: "center",
    color: palette.purpleBlue,
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    textDecoration: "none",
    userSelect: "none",
  },
});

export const LinkArrow = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "36px",
  height: "36px",
  marginLeft: "16px",
  color: palette.primary,
  backgroundColor: palette.secondary,
  border: `1px solid ${palette.purpleBlue}`,
  borderRadius: "50%",

  "& svg path": {
    fill: palette.purpleBlue,
  },

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
});

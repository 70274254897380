import React from "react";
import palette from "../../theme/palette";

const ResetArrowIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M3.88533 4.66686L5.576 6.35753L4.63333 7.30019L1.33333 4.0002L4.63333 0.700195L5.576 1.64286L3.88533 3.33353H8.66666C10.0812 3.33353 11.4377 3.89543 12.4379 4.89563C13.4381 5.89582 14 7.25237 14 8.66686C14 10.0813 13.4381 11.4379 12.4379 12.4381C11.4377 13.4383 10.0812 14.0002 8.66666 14.0002H2.66666L2.66666 12.6669H8.66666C9.72753 12.6669 10.7449 12.2454 11.4951 11.4953C12.2452 10.7451 12.6667 9.72773 12.6667 8.66686C12.6667 7.606 12.2452 6.58858 11.4951 5.83843C10.7449 5.08829 9.72753 4.66686 8.66666 4.66686H3.88533Z"
      />
    </svg>
  );
};

export default ResetArrowIcon;

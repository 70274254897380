import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Seo from "../components/seo";
import { CasesTitle } from "../styles/cases";

import Container from "../components/container";
import { Formik } from "formik";
import { useGetCasesLazy } from "../graphql/hooks/useQueries";
import CasesContent from "../components/cases";

function Cases({
                 data: {
                   strapi: {
                     casesCatalog,
                     categoryIsEmpty,
                     cases,
                     categories,
                     technologiesLists,
                   },
                 },
                 ...rest
               }) {
  const [getCases, { data: filteredCases, loading }] = useGetCasesLazy();

  const handleSubmit = (values) => {
    let categories = {
      id: {
        eq: values.category,
      },
    };

    let stackTechnologies = {
      id: {
        in: values.stackTechnology?.map((item) => {
          return Number(item.id);
        }),
      },
    };

    if (values.category === "all") {
      categories = {};
    }

    if (values.stackTechnology[0].attributes.title === "All") {
      stackTechnologies = {};
    }

    getCases({
      variables: {
        filters: {
          categories: categories,
          stackTechnologies: stackTechnologies,
        },
        pagination: {
          limit: 100,
        },
      },
    });
  };

  return (
      <Layout>
        <Seo
            title={casesCatalog?.data?.attributes?.seo?.metaTitle}
            description={casesCatalog?.data?.attributes?.seo?.metaDescription}
            image={
              casesCatalog?.data?.attributes?.seo?.metaImage?.data?.attributes?.url
            }
        />
        <Container direction="column">
          <CasesTitle
              variant="h1"
              dangerouslySetInnerHTML={{
                __html: casesCatalog?.data?.attributes?.title,
              }}
          />
          {/* CASES FILTER */}
          <Formik
              initialValues={{
                category: "all",
                stackTechnology: [{ id: null, attributes: { title: "All" } }],
              }}
              onSubmit={handleSubmit}
          >
            {({
                values,
                resetForm,
                handleSubmit: formikHandleSubmit,
                handleChange,
                setFieldValue,
              }) => (
                <CasesContent
                    loading={loading}
                    values={values}
                    resetForm={resetForm}
                    cases={cases}
                    categories={categories}
                    filteredCases={filteredCases}
                    formikHandleSubmit={formikHandleSubmit}
                    categoryIsEmpty={categoryIsEmpty}
                    onChange={handleChange}
                    search={rest.location.search}
                    pathname={rest.location.pathname}
                    technologiesLists={technologiesLists}
                    setFieldValue={setFieldValue}
                />
            )}
          </Formik>
          {/* END OF CASES FILTER */}
        </Container>
      </Layout>
  );
}

export default Cases;

export const query = graphql`
  query {
    strapi {
      casesCatalog {
        data {
          attributes {
            seo {
              ...seo
            }
            title
          }
        }
      }

      categoryIsEmpty {
        data {
          attributes {
            text
          }
        }
      }

      cases {
        data {
          id
          attributes {
            slug
            title
            description
            mainImage {
              data {
                attributes {
                  url
                  alternativeText
                  caption
                }
              }
            }
            mainCategory {
              data {
                attributes {
                  preview {
                    title
                  }
                }
              }
            }
            stackTechnologies {
              data {
                id
                attributes {
                  title
                }
              }
            }
          }
        }
      }
      categories {
        data {
          id
          attributes {
            slug
            preview {
              title
            }
          }
        }
      }
      technologiesLists(pagination: { limit: 100 }) {
        data {
          id
          attributes {
            slug
            title
          }
        }
      }
    }
  }
`;
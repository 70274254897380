import React from "react";
import { Oval } from "react-loader-spinner";
import palette from "../../theme/palette";
const Loader = () => {
  return (
      <Oval
          height={80}
          width={80}
          color={palette.carbonGrey}
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          center={true}
          ariaLabel='oval-loading'
          secondaryColor={palette.amethystSmoke}
          strokeWidth={2}
          strokeWidthSecondary={2}
      />
  );
}
export default Loader;
import React from "react";

const ArrowDownIcon = ({ width = "20px", height = "20px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="arrowWrapper"
    >
      <path
        d="M9.99999 10.9766L14.125 6.85156L15.3033 8.0299L9.99999 13.3332L4.69666 8.0299L5.87499 6.85156L9.99999 10.9766Z"
        fill="#151515"
      />
    </svg>
  );
};

export default ArrowDownIcon;

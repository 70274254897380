import React, { useState } from "react";
import { HOST_IMAGE_URL } from "../../constants";
import { PortfolioWrapper } from "./style";
import CardContainer from "./CardContainer";
import { Link } from "gatsby";
import { useBreakpointValue } from "../../hooks/use-breakpoint-value";

const CasesPreview = ({ data }) => {
  const [visibleCard, setVisibleCard] = useState(false);
  const handleToggleCard = () => {
    setVisibleCard((prev) => !prev);
  };

  const isMobile = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);

  const LinkDesktop = !isMobile ? Link : "div";
  const LinkMobile = isMobile ? Link : "div";

  return (
    <PortfolioWrapper
      onMouseEnter={handleToggleCard}
      onMouseLeave={handleToggleCard}
      key={data?.id}
    >
      <LinkDesktop to={`/cases/${data?.attributes?.slug}`} className="wrapper">
        <LinkMobile
          to={`/cases/${data?.attributes?.slug}`}
          className="linkMobile"
        >
          <img
            src={`${
              HOST_IMAGE_URL +
              data?.attributes?.mainImage?.data?.attributes?.url
            }`}
            alt={data?.attributes?.mainImage?.data?.attributes?.alternativeText}
            title={data?.attributes?.mainImage?.data?.attributes?.caption}
          />
        </LinkMobile>
        <CardContainer
          visibleCard={visibleCard}
          mainCategory={
            data?.attributes?.mainCategory?.data?.attributes?.preview?.title
          }
          title={data?.attributes?.title}
          description={data?.attributes?.description}
          url={`/cases/${data?.attributes?.slug}`}
        />
      </LinkDesktop>
    </PortfolioWrapper>
  );
};

export default CasesPreview;

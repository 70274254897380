import React from "react";
import Select from "@mui/material/Select";
import { LabelWrapper, SelectWrapper, styles } from "./styles";
import { useField } from "formik";
import { Arrow } from "./Arrow";

const MenuProps = (multiple) => {
  return {
    PaperProps: {
      sx: {
        ...(multiple
          ? { ...styles.selectMenu, ...styles.multipleSelectMenu }
          : styles.selectMenu),
      },
    },
  };
};

export default function CustomSelect({
  children,
  width,
  label,
  multiple = false,
  name,
  handleOnChange,
  ...restSelectProps
}) {
  const [field] = useField(name);

  const handleMultipleChange = (event) => {
    const {
      target: { value: selectOptions },
    } = event;

    let finalOptions = selectOptions.slice();

    if (!finalOptions.length) {
      finalOptions = ["all"];
    }

    // if selected more than one item
    else if (selectOptions.length > 1) {
      // if first selected is "All" ==> remove it
      if (selectOptions[0] === "all") {
        finalOptions = selectOptions.slice(1);
        // if last selected is "All" ==> remove all items except "All"
      } else if (selectOptions[selectOptions.length - 1] === "all") {
        finalOptions = selectOptions.slice(-1);
      }
    }

    event.target.value = finalOptions;

    field.onChange(name)(event);
  };

  const localOnChange = (event) => {
    handleOnChange?.(event);
    field.onChange(name)(event);
  };

  return (
    <SelectWrapper sx={{ maxWidth: width }}>
      <LabelWrapper>{label}</LabelWrapper>
      <Select
        multiple={multiple}
        {...field}
        onChange={localOnChange}
        {...(multiple && { onChange: handleMultipleChange })}
        IconComponent={Arrow}
        MenuProps={MenuProps(multiple)}
        {...restSelectProps}
      >
        {children}
      </Select>
    </SelectWrapper>
  );
}

import palette from "../../theme/palette";
import { styled } from "@mui/system";
import theme from "../../theme";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// DEFAULT CASE SECTION
export const DefaultCaseWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginTop: "-52px",
  padding: "36px",
  backgroundColor: palette.blueChalk,

  [theme.breakpoints.down("xl")]: {
    flexDirection: "column",
    width: "100%",
    padding: "26px",
  },

  [theme.breakpoints.down("md")]: {
    marginTop: "0px",
    padding: "16px",
  },
});

export const DefaultCaseInfo = styled(Box)({
  maxWidth: "644px",
  width: "100%",
  padding: "36px",
  backgroundColor: palette.secondary,

  [theme.breakpoints.down("xl")]: {
    maxWidth: "100%",
    padding: "26px",
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
  },

  [theme.breakpoints.down("md")]: {
    padding: "16px",
  },
});

export const DefaultCasePreTitle = styled(Typography)({
  letterSpacing: "-0.01em",
});

export const DefaultCaseTitle = styled(Typography)({
  margin: "2px 0 8px",
  display: "block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  lineHeight: "102px",
});

export const DefaultCaseSubTitle = styled(Typography)({
  maxWidth: "430px",
  width: "100%",
  letterSpacing: "0.1px",

  [theme.breakpoints.down("xl")]: {
    maxWidth: "100%",
  },
});

export const DefaultCaseResetButton = styled(Button)({
  display: "flex",
  alignItems: "center",
  marginTop: "44px",
  padding: 0,
  color: palette.purpleBlue,
  textTransform: "none",

  "&.MuiButtonBase-root.MuiButton-root:hover": {
    backgroundColor: "transparent",
  },

  [theme.breakpoints.down("xl")]: {
    marginTop: "26px",
  },

  [theme.breakpoints.down("lg")]: {
    marginTop: "44px",
  },

  [theme.breakpoints.down("sm")]: {
    marginTop: "26px",
  },
});

export const DefaultCaseResetIcon = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "36px",
  height: "36px",
  marginLeft: "16px",
  color: palette.primary,
  backgroundColor: palette.secondary,
  border: `1px solid ${palette.purpleBlue}`,
  borderRadius: "50%",

  "& svg path": {
    fill: palette.purpleBlue,
  },

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
});

export const DefaultCaseImage = styled(Box)({
  height: "320px",
  marginLeft: "20px",

  "& svg": {
    width: "320px",
    height: "320px",
  },

  [theme.breakpoints.down("xl")]: {
    display: "none",
  },
});
// DEFAULT CASE SECTION
